
.detail-contain {
    height: 100%;

    .back-box {
        display: flex;
        justify-content: space-between;

        .interactive-breadcrumb {
            font-size: 14px;
            margin-top: 9px;
        }

        .back-btn {

            ::v-deep .el-button {
                width: 80px;
                height: 32px;
                background: #4D5AFF;
                border-radius: 2px;
                color: #ffffff;
                line-height: 9px;
            }
        }
    }

    .head-box {
        position: relative;
        width: 100%;
        margin-top: 10px;

        .first-box {
            background-image: url("../../../assets/img/company/dataBack.png");
            height: 192px;
        }

        .second-box {
            height: 192px;
            background: #FFFFFF;
            border-radius: 8px;
            width: calc(100% - 52px);
            position: absolute;
            top: 50px;
            left: 26px;
            display: flex;

            .left-box {
                width: 50%;
                min-width: 404px;
                position: relative;

                .left-top-box {
                    margin-top: 13px;
                    width: 135px;
                    height: 30px;
                    background: #FF6C00;
                    position: relative;
                    color: #ffffff;
                    font-size: 14px;
                    line-height: 30px;
                    display: flex;

                    div {
                        margin-left: 6px;
                    }

                    span {
                        font-size: 16px;
                        font-weight: Bold;
                        margin-left: 6px;
                    }

                    &:before {
                        width: 0;
                        height: 0;
                        content: '';
                        position: absolute;
                        left: -10px;
                        border-top-left-radius: 2px;
                        border-bottom-right-radius: 2px;
                        border-top: 30px solid #FF6C00;
                        border-left: 10px solid transparent;
                    }

                    &:after {
                        width: 0;
                        height: 0;
                        content: '';
                        position: absolute;
                        right: -15px;
                        border-top-right-radius: 2px;
                        border-top: 30px solid #FF6C00;
                        border-right: 15px solid transparent;
                    }
                }

                .left-bottom-box {
                    display: flex;
                    padding: 17px 49px 29px 40px;

                    .left-bottom-avatar {
                        width: 90px;
                        height: 90px;

                        img {
                            width: 90px;
                            height: 90px;
                            border-radius: 50%;
                        }
                    }

                    .left-bottom-headInfo {
                        margin-top: 10px;
                        margin-left: 16px;

                        .headInfo-name {
                            color: #333333;
                            font-size: 16px;
                        }

                        .headInfo-sign {
                            margin-top: 14px;
                            font-size: 14px;
                            color: #999999;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: normal;
                            word-break: break-all;
                            word-wrap: break-word;
                            max-height: 30px;
                            line-height: 16px;
                        }

                        .headInfo-url {
                            width: 97px;
                            height: 24px;
                            background: #FFFFFF;
                            border: 1px solid #EEEEEE;
                            border-radius: 2px;
                            margin-top: 8px;
                            display: flex;
                            font-size: 12px;
                            align-items: center;
                            cursor: pointer;

                            span {
                                margin-left: 5px;
                            }

                            .el-icon-position {
                                margin-left: 3px;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            .middle-box {
                width: 690px;
                position: relative;

                .update-time {
                    position: absolute;
                    right: 20px;
                    top: 20px;

                    span {
                        color: #666666;
                        font-size: 12px;
                    }
                }

                .middle-top-box {
                    margin-top: 40px;
                    display: flex;

                    .data-box {
                        width: 293px;
                        display: flex;
                        justify-content: space-around;

                        .data-item {
                            display: flex;
                            flex-direction: column;

                            .text {
                                color: #666666;
                                font-size: 14px;
                                margin-top: 8px;
                            }

                            .data-num {
                                font-size: 24px;
                                color: #42D7AB;
                                text-align: center;
                            }
                        }
                    }
                }

                .middle-bottom-box {
                    display: flex;
                    justify-content: space-around;
                    margin-top: 20px;
                    width: 433px;

                    .data-item {
                        display: flex;
                        flex-direction: column;

                        .text {
                            color: #666666;
                            font-size: 14px;
                            margin-top: 8px;
                        }

                        .data-num {
                            font-size: 24px;
                            color: #FF6C00;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .table-box {
        margin-top: 70px;
        background: #ffffff;

        .table-title {
            font-size: 16px;
            color: #333333;
            padding: 30px 0 20px 30px;
            position: relative;

            &:before {
                content: '';
                width: 3px;
                height: 10px;
                background: #00B47F;
                position: absolute;
                left: 20px;
                top: 35px;
            }
        }

        .college-table {
            width: 100%;
            overflow: hidden;
            min-height: 520px;

            .showing-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .pages-center {
            ::v-deep .el-pager li.active {
                background: #4D5AFF;
                border-radius: 50%;
                color: #FFFFFF;
                width: 24px !important;
                height: 24px !important;
            }
        }
    }
}
